import React, { useEffect } from 'react';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
import CableCut from 'public/images/cable-cut.png';

const Error404 = () => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const router = useRouter();
  useEffect(() => {
    router.push('/not-found');
  });
  return <></>;
};

export default Error404;
